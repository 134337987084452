import {CredentialProvider, FederatedCredentials} from '../../../../fedcm';
import {DefaultComponentMonorailTracker} from '~/__deprecated__/components/loginButton/analytics';

interface InitFedCMParams {
  mediation?: CredentialMediationRequirement;
  analyticsTraceId?: string;
  monorailTracker?: DefaultComponentMonorailTracker;
}

export class FedCMNotSupportedError extends Error {
  constructor() {
    super('FedCM is not supported');
  }
}

export class FedCMCancelledError extends Error {
  constructor() {
    super('FedCM was cancelled');
  }
}

export const initFedCM = async (params: InitFedCMParams): Promise<Response> => {
  // Browser does not support FedCM
  if (!('IdentityCredential' in window)) {
    throw new FedCMNotSupportedError();
  }

  const {mediation = 'optional', analyticsTraceId, monorailTracker} = params;

  const providers = await getFedCMProviders(analyticsTraceId);

  const credential = await getFedCMCredentials(mediation, providers);
  if (!credential) {
    monorailTracker?.trackFedCMCancelledUserAction();
    throw new FedCMCancelledError();
  }

  return submitTokenToServer(
    credential.token,
    providers.state,
    monorailTracker,
  );
};

function getFedCMCredentials(
  mediation: CredentialMediationRequirement,
  providers: CredentialProvider,
) {
  return (navigator.credentials as FederatedCredentials).get({
    identity: {
      providers: [providers],
    },
    mediation,
  });
}

async function getFedCMProviders(
  analyticsTraceId?: string,
): Promise<CredentialProvider> {
  let url = '/services/login_with_shop/fedcm/provider';
  if (analyticsTraceId) {
    url += `?analyticsTraceId=${encodeURIComponent(analyticsTraceId)}`;
  }

  const response = await fetch(url, {
    method: 'GET',
  });

  const body = await response.json();

  return {
    configURL: body.configURL,
    clientId: body.clientId,
    nonce: body.nonce,
    state: body.state,
  };
}

async function submitTokenToServer(
  token: string,
  state: string,
  monorailTracker?: DefaultComponentMonorailTracker,
) {
  const response = await fetch('/services/login_with_shop/fedcm/callback', {
    method: 'POST',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      raw_id_token: token,
      state,
    }).toString(),
  });

  monorailTracker?.trackFedCMCompletedUserAction();

  return response;
}
