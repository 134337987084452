import PasswordManagerDetection from '__deprecated__/common/utils/passwordManagerDetection';
import {StorageManager} from '__deprecated__/common/utils/storageManager';

import InputListener from '../../common/InputListener';
import Bugsnag from '../../common/bugsnag';
import {SIGN_IN_FORM_EMAIL_INPUT_SELECTOR} from '../../common/constants';
import {
  createElementLocator,
  createElementVisibilityObserver,
  getAnalyticsTraceId,
  getFeatures,
  getQueryParam,
  removeTrailingSlash,
} from '../../common/utils';
import {getFunctionCalled, setFunctionCalled} from '../../common/init';
import {ClassicCustomerAccountsMonorailTracker} from '../../components/loginButton/analytics';
import ShopLoginButton from '../../components/loginButton/shop-login-button';
import {DefaultComponentAnalyticsContext} from '../../constants/loginDefault';
import {ClassicCustomerAccountsFlowVersion} from '../../types';
import {
  FedCMCancelledError,
  FedCMNotSupportedError,
  initFedCM,
} from './initFedCM';

/**
 * Initialize Login with Shop on the customer account pages.
 */
export async function initCustomerAccounts(invokeFedCM = false) {
  if (getFunctionCalled('initCustomerAccounts')) {
    return;
  }

  setFunctionCalled('initCustomerAccounts');

  try {
    const pathName = removeTrailingSlash(window.location.pathname);
    if (pathName.endsWith('/account')) {
      initCustomerAccountPage();
      return;
    }

    initClassicCustomerAccountsSignInForm(invokeFedCM);
  } catch (error) {
    if (error instanceof Error) {
      Bugsnag.notify(error);
    }
    if (error instanceof InitCustomerAccountsError) {
      const monorailTracker = new ClassicCustomerAccountsMonorailTracker({
        elementName: 'shop-login-button',
        flowVersion: ClassicCustomerAccountsFlowVersion.SignIn,
        analyticsTraceId: error.analyticsTraceId,
      });
      monorailTracker.trackShopPayLoginWithSdkErrorEvents({
        apiKey: '',
        errorCode: error.code,
        errorMessage: error.message,
      });
    }
  }
}

/**
 * Initialize Login with Shop on the customer account page.
 */
function initCustomerAccountPage() {
  const analyticsTraceIdQueryParam = getQueryParam('analytics_trace_id');
  if (analyticsTraceIdQueryParam) {
    const monorailTracker = new ClassicCustomerAccountsMonorailTracker({
      elementName: 'shop-login-button',
      flowVersion: ClassicCustomerAccountsFlowVersion.SignIn,
      analyticsTraceId: analyticsTraceIdQueryParam,
    });
    monorailTracker.trackClassicCustomerAccountsAccountPageImpression();
  }
}

/**
 * Initializes the Login with Shop Sign in form for classic customer accounts.
 */
function initClassicCustomerAccountsSignInForm(invokeFedCM = false) {
  const analyticsTraceId = getAnalyticsTraceId();
  const monorailTracker = new ClassicCustomerAccountsMonorailTracker({
    elementName: 'shop-login-button',
    flowVersion: ClassicCustomerAccountsFlowVersion.SignIn,
    analyticsTraceId,
  });
  const inputListenerMap = new WeakMap<HTMLInputElement, InputListener>();
  let shopLoginButton: ShopLoginButton | null = null;

  if (invokeFedCM) {
    initClassicCustomerAccountsSignInFedCM();
  }

  const elementVisibilityObserver =
    createElementVisibilityObserver<HTMLInputElement>({
      onVisible: (input: HTMLInputElement) =>
        createClassicCustomerAccounts({input, autoOpen: !invokeFedCM}),
      onFallback: (element) => {
        element.addEventListener('focus', handleInputFocus, {once: true});
        monorailTracker.trackShopPayLoginWithSdkErrorEvents({
          apiKey: '',
          errorCode: 'fallback_to_focus_event',
          errorMessage: 'Fallback to focus event for classic customer accounts',
        });
      },
    });

  createElementLocator<HTMLInputElement>({
    selector: SIGN_IN_FORM_EMAIL_INPUT_SELECTOR,
    onElementFound: (input) => elementVisibilityObserver.observe(input),
  });

  /**
   * Handles the focus event on the email input. This will create the Login with Shop button and remove the focus event listener.
   * @param {Event} event The focus event emitted on the detected email input.
   */
  function handleInputFocus(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    createClassicCustomerAccounts({input, autoOpen: !invokeFedCM});
  }

  /**
   * Determines the correct URL to redirect to when the user Signs in with Shop
   * to be consistent with where they'll land when signing in with email and password.
   * @param {HTMLFormElement} form The form to look up redirect-related inputs from.
   * @param {string} analyticsTraceId The analytics trace ID to pass onward to the redirect endpoint.
   * @returns {string} The URL to redirect to after a successful signin.
   */
  function signInRedirectUri(
    form: HTMLFormElement,
    analyticsTraceId: string,
  ): string {
    const checkoutInputUrl = (
      form.elements.namedItem('checkout_url') as HTMLInputElement | undefined
    )?.value;
    const returnInputUrl = (
      form.elements.namedItem('return_url') as HTMLInputElement | undefined
    )?.value;

    /* eslint-disable @typescript-eslint/naming-convention */
    const queryParams = new URLSearchParams({
      analytics_trace_id: analyticsTraceId,
      ...(checkoutInputUrl && {checkout_url: checkoutInputUrl}),
      ...(returnInputUrl && {return_url: returnInputUrl}),
    });

    /* eslint-enable @typescript-eslint/naming-convention */
    const redirectUri = `${
      window.location.origin
    }/account/redirect?${queryParams.toString()}`;

    return redirectUri;
  }

  /**
   * Creates the Login with Shop button and initializes the customer account Sign In page.
   * @param {HTMLInputElement} input The detected email input element.
   */
  function createClassicCustomerAccounts({
    input,
    autoOpen,
  }: {
    input: HTMLInputElement;
    autoOpen: boolean;
  }) {
    const form = input.form;
    if (!form) {
      Bugsnag.notify(
        new Error('Email form missing for classic customer accounts'),
      );
      return;
    }

    if (inputListenerMap.has(input)) {
      Bugsnag.notify(new Error('Input listener already exists for input'));

      inputListenerMap.get(input)?.destroy();
      inputListenerMap.delete(input);
    }

    // Add hidden analytics trace id to form
    const analyticsTraceIdHiddenInput = document.createElement('input');
    analyticsTraceIdHiddenInput.type = 'hidden';
    analyticsTraceIdHiddenInput.name = 'login_with_shop[analytics_trace_id]';
    analyticsTraceIdHiddenInput.value = analyticsTraceId;
    form.appendChild(analyticsTraceIdHiddenInput);

    const storageManager = new StorageManager('modalDismissed', false);

    // Init login form if it hasn't been initialized yet
    if (!shopLoginButton) {
      shopLoginButton = initCustomerAccountsSignInPage({
        analyticsTraceId,
        autoOpen,
      });
      monorailTracker.trackClassicCustomerAccountsLoginPageImpression();

      shopLoginButton.addEventListener('completed', () => {
        const redirectUri = signInRedirectUri(form, analyticsTraceId);
        window.location.assign(redirectUri);
      });

      shopLoginButton.addEventListener('modalclosed', () => {
        storageManager.set(true);
      });
    }

    // Track password manager usage to prevent Sign in with Shop action to avoid skewing our conversion metrics
    const passwordManagerDetection = new PasswordManagerDetection(input);
    passwordManagerDetection.start();
    shopLoginButton.setPasswordManagerDetection?.(passwordManagerDetection);

    // Add input listener to email input
    shopLoginButton.email = input.value;

    inputListenerMap.set(
      input,
      new InputListener(input, (value) => {
        shopLoginButton!.email = value;
      }),
    );
  }

  /**
   * Initialize Sign in with Shop via FedCM on the customer account login page
   */
  function initClassicCustomerAccountsSignInFedCM() {
    // This effectively inforces fedCM to be used on the login page
    const form = document.querySelector('#customer_login') as HTMLFormElement;

    if (!form) {
      return;
    }

    // if this script gets called, it means the  beta flag is enabled
    initFedCM({
      mediation: 'required',
      analyticsTraceId,
      monorailTracker,
    })
      .then((res) => fedCMSucceeded(res, form, analyticsTraceId))
      .catch((error) => fedCMFailed(error));
  }

  function fedCMSucceeded(
    response: Response,
    form: HTMLFormElement,
    analyticsTraceId: string,
  ) {
    if (response.status !== 200) {
      Bugsnag.notify(new Error('FedCM failed to authenticate'));

      return;
    }

    if (!form) {
      Bugsnag.notify(
        new Error(
          'FedCM failed to find the nearest form to leverage sign in URL',
        ),
      );

      return;
    }
    const redirectUri = signInRedirectUri(form, analyticsTraceId);
    window.location.assign(redirectUri);
  }

  function fedCMFailed(error: any): any {
    if (
      error instanceof FedCMNotSupportedError ||
      error instanceof FedCMCancelledError ||
      (error.name === 'NetworkError' &&
        error.message === 'Error retrieving a token.')
    ) {
      return;
    }

    Bugsnag.notify(error);
  }
}

/**
 * Initialize Login with Shop on the customer account Sign In page.
 * @param {string} analyticsTraceId The analytics trace ID.
 * @returns {ShopLoginButton} A login button that has been appended to the document.
 */
function initCustomerAccountsSignInPage({
  analyticsTraceId,
  autoOpen,
}: {
  analyticsTraceId: string;
  autoOpen: boolean;
}): ShopLoginButton {
  let appendButtonProgrammatically = false;
  let button: ShopLoginButton | null;

  // Finds a button on the page that does not have an action assigned to it (e.g. Follow)
  button = document.querySelector(
    'shop-login-button:not([action])',
  ) as ShopLoginButton | null;

  if (!button) {
    button = document.createElement('shop-login-button') as ShopLoginButton;

    // Prevent overriding the hide-button attribute specified by merchants
    button.setAttribute('hide-button', 'true');
    appendButtonProgrammatically = true;
  }

  button.setAttribute('client-id', '');
  button.setAttribute('action', 'default');
  button.setAttribute('version', '2');
  button.setAttribute(
    'flow-version',
    ClassicCustomerAccountsFlowVersion.SignIn,
  );
  button.setAttribute(
    'analytics-context',
    DefaultComponentAnalyticsContext.ClassicCustomerAccounts,
  );
  button.setAttribute('analytics-trace-id', analyticsTraceId);
  button.setAttribute('disable-sign-up', 'true');
  if (autoOpen) {
    button.setAttribute('auto-open', 'true');
  }
  button.setAttribute('consent-challenge', '');

  Object.entries(getFeatures()).forEach(([feature, value]) => {
    // Beta flags can be underscored, but attribute values should use dashes as seperators
    const featureDashCased = feature.replace(/_/g, '-');
    button!.setAttribute(featureDashCased, value);
  });

  if (appendButtonProgrammatically) {
    document.body.appendChild(button);
  }

  return button;
}

export class InitCustomerAccountsError extends Error {
  name = 'InitCustomerAccountsError';
  code = 'init_customer_accounts_error';

  constructor(
    message: string,
    public analyticsTraceId: string = getAnalyticsTraceId(),
  ) {
    super(message);
  }
}
